.node {
  box-sizing: border-box;
  contain: layout;
}

.root {
  will-change: transform;
}

.child {
  margin-top: 5px;
  max-width: max-content;
}

.child_area {
  position: relative;
  padding-left: 25px;
  display: block;
  contain: layout;
}

.selection_container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
  background-color: rgb(195, 231, 240);
  min-width: 20px;
  width: max-content;
  border-radius: 10px;
}

.selection_container.selected {
  margin: -1px;
  border: 1px solid blue;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 255, 0.7);
}

.selection_container.selected:hover {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 255, 0.5);
}

.selection_container:hover {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 200ms, transform 200ms, z-index 200ms;
  z-index: 10;
}

.child_holder {
  position: relative;
  contain: layout;
}

.node_label {
  max-width: 100%;
  cursor: default;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dragged {
  z-index: 10;
  opacity: 0.6;
}

.beacon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: -3px;
  background: orange;
}

.beacon.closest {
  background: green;
}

.label-editor {
  position: absolute;
}

.parent_edge {
  border-bottom: 1px solid rgb(50, 50, 50);
  border-left: 1px solid rgb(50, 50, 50);
  box-sizing: border-box;
  position: absolute;
  right: 100%;
  top: -5px; /* this is margin spread of children */
  height: 25px; /* 20 + margin again */
  width: 13px;
  border-radius: 0 0 0 50%;
}